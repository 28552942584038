const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_LIST_CURRENCY: 'GET_LIST_CURRENCY',
  GET_LIST_CURRENCY_SUCCESS: 'GET_LIST_CURRENCY_SUCCESS',

  GET_ADDRESS_BY_CURRENCY_LIST: 'GET_ADDRESS_BY_CURRENCY_LIST',
  GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS: 'GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getListCurrency: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_CURRENCY,
    payload,
    cbs,
    cbe,
  }),

  getAddressByCurrencyList: (payload) => ({
    type: actions.GET_ADDRESS_BY_CURRENCY_LIST,
    payload,
  }),
  getAddressByCurrencyListSuccess: (payload) => ({
    type: actions.GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS,
    payload,
  }),
}
export default actions
