import { all, takeEvery, call, put, fork, select } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getCurrencyFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/currency/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListCurrency() {
  yield takeEvery(actions.GET_LIST_CURRENCY, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getCurrencyFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_CURRENCY_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListCurrency', error)
      if (cbe) cbe(error)
    }
  })
}

/**
 * Get address of token by currency list _id
 */

function getAddressByCurrencyListFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/currencyAttr/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getAddressByCurrencyList() {
  yield takeEvery(actions.GET_ADDRESS_BY_CURRENCY_LIST, function* ({ payload }) {
    try {
      // fetch list address currency by currency id
      const fetchList = []
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i]
        fetchList.push(call(getAddressByCurrencyListFromApi, { currency_id: element }))
      }

      const response = yield all(fetchList)
      if (response && response.length > 0) {
        const dataAddressByCurrencyId = {}
        response.forEach((element, index) => {
          if (element.data.code === 200) {
            const tokenId = payload[index]
            dataAddressByCurrencyId[tokenId] = element.data.data
          }
        })
        const listBlockChain = yield select((state) => state?.BlockChain?.listBlockChain?.data)
        const listCurrency = yield select((state) => state?.Currency?.listCurrency)

        // console.log('dataAddressByCurrencyId', dataAddressByCurrencyId)
        // console.log('listBlockChain', listBlockChain)
        // console.log('listCurrency', listCurrency)

        const mapKeyNetwork = {
          'BSC Chain': 'bsc',
          POLYGON: 'polygon',
        }

        const mapCurrencyWithAddress = [...listCurrency].map((currency) => {
          const tmpCurrency = currency
          const dataAddressByCurrencyIdItem = dataAddressByCurrencyId[currency._id]
          listBlockChain.forEach((chain) => {
            const fAddress = dataAddressByCurrencyIdItem.find((p) => p.blockchain === chain._id)
            tmpCurrency[chain._id] = fAddress?.contract
            tmpCurrency[mapKeyNetwork[chain.title]] = fAddress?.contract
          })
          return tmpCurrency
        })

        yield put({
          type: actions.GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS,
          data: mapCurrencyWithAddress,
        })
      }
    } catch (error) {
      console.error('getAddressByCurrencyList', error)
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getListCurrency),
    fork(getAddressByCurrencyList),
    // fork(getListCurrency)
  ])
}
