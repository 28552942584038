export const PUBLIC_ROUTE = {
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
}

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
  PROFILE: '/my-profile',
  MANAGER_ADMIN_USER: '/manager/admin',
  MANAGER_CURRENCY: '/manager/currency',
  MANAGER_CHAIN: '/manager/chain',
  MANAGER_WALLET_ADMIN: '/manager/wallet',
  MANAGER_COUNTRY: '/manager/country',
  MANAGER_CUSTOMER_UPDATE: '/update/customer/:id',
  MANAGER_CUSTOMER_DETAILS: '/details/customer/:id',
  MANAGER_WALLET_DETAILS: '/details-wallet/:id',

  REPORT_WALLET: '/report/wallet',
  REPORT_CUSTOMER: '/report/customer',
  REPORT_TRANSACTION: '/report/transaction',

  STAKING_LIST: '/packages',
  GAME_MANAGER: '/game-manager',
}
