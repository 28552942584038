const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_LIST_TRANSACTION: 'GET_LIST_TRANSACTION',
  GET_LIST_TRANSACTION_SUCCESS: 'GET_LIST_TRANSACTION_SUCCESS',
  GET_TRANSACTION_BY_CUSTOMER: 'GET_TRANSACTION_BY_CUSTOMER',
  APPROVE_TRANSACTION: 'APPROVE_TRANSACTION',
  REJECT_TRANSACTION: 'REJECT_TRANSACTION',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  getListTransaction: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_TRANSACTION,
    payload,
    cbs,
    cbe,
  }),
  getTransactionByCustomer: (payload, cbs, cbe) => ({
    type: actions.GET_TRANSACTION_BY_CUSTOMER,
    payload,
    cbs,
    cbe,
  }),
  approveTransaction: (payload, cbs, cbe) => ({
    type: actions.APPROVE_TRANSACTION,
    payload,
    cbs,
    cbe,
  }),
  rejectTransaction: (payload, cbs, cbe) => ({
    type: actions.REJECT_TRANSACTION,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
