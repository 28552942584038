import actions from './actions'

const initState = { listBlockChain: null }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LIST_BLOCK_CHAIN_SUCCESS:
      const mapKeyNetwork = {
        'BSC Chain': 'bsc',
        POLYGON: 'polygon',
      }
      const customData = action.data.data.map((it) => ({
        ...it,
        chain: [mapKeyNetwork[it.title]],
      }))

      return {
        ...state,
        listBlockChain: {
          data: customData,
        },
      }
    default:
      return state
  }
}
