import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getCustomerUserFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/customer/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListCustomer() {
  yield takeEvery(actions.GET_LIST_CUSTOMER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getCustomerUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_CUSTOMER_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListCustomer', error)
      if (cbe) cbe(error)
    }
  })
}

function* getListSearchCustomer() {
  yield takeEvery(actions.GET_LIST_SEARCH_CUSTOMER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getCustomerUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        return data.data
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListSearchCustomer', error)
      if (cbe) cbe(error)
    }
  })
}

function createUserFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/customer/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createUser() {
  yield takeEvery(actions.CREATE_CUSTOMER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(createUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('createUser', error)
      if (cbe) cbe(error)
    }
  })
}

function getListCountriesFromApi(payload) {
  return fetchHelper
    .fetch(
      `${ROOT_API}/country/list`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      {
        isToastSuccess: false,
        isToastFailed: false,
      },
    )
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListCountries() {
  yield takeEvery(actions.GET_LIST_COUNTRIES, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListCountriesFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_COUNTRIES_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListCountries', error)
      if (cbe) cbe(error)
    }
  })
}

function getProfileCustomerFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/customer/detail?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getProfileCustomer() {
  yield takeEvery(actions.GET_PROFILE_CUSTOMER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getProfileCustomerFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_PROFILE_CUSTOMER_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getProfileCustomer', error)
      if (cbe) cbe(error)
    }
  })
}

function* getCustomer() {
  yield takeEvery(actions.GET_CUSTOMER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getProfileCustomerFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        return data.data
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getProfileCustomer', error)
      if (cbe) cbe(error)
    }
  })
}

function updateProfileCustomerFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/customer/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateProfileCustomer() {
  yield takeEvery(actions.UPDATE_PROFILE_CUSTOMER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(updateProfileCustomerFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('updateProfileCustomer', error)
      if (cbe) cbe(error)
    }
  })
}

function getListStatusFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/staticData/data?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListStatus() {
  yield takeEvery(actions.GET_LIST_STATUS, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListStatusFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_STATUS_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListStatus', error)
      if (cbe) cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getListCustomer),
    fork(createUser),
    fork(getListCountries),
    fork(getProfileCustomer),
    fork(updateProfileCustomer),
    fork(getCustomer),
    fork(getListStatus),
    fork(getListSearchCustomer),
  ])
}
