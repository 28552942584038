const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_LIST_CUSTOMER: 'GET_LIST_CUSTOMER',
  GET_LIST_CUSTOMER_SUCCESS: 'GET_LIST_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  GET_LIST_COUNTRIES: 'GET_LIST_COUNTRIES',
  GET_LIST_COUNTRIES_SUCCESS: 'GET_LIST_COUNTRIES_SUCCESS',
  GET_PROFILE_CUSTOMER: 'GET_PROFILE_CUSTOMER',
  GET_CUSTOMER: 'GET_CUSTOMER',
  GET_PROFILE_CUSTOMER_SUCCESS: 'GET_PROFILE_CUSTOMER_SUCCESS',
  UPDATE_PROFILE_CUSTOMER: 'UPDATE_PROFILE_CUSTOMER',
  GET_LIST_STATUS: 'GET_LIST_STATUS',
  GET_LIST_STATUS_SUCCESS: 'GET_LIST_STATUS_SUCCESS',
  GET_LIST_SEARCH_CUSTOMER: 'GET_LIST_SEARCH_CUSTOMER',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getListCustomer: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_CUSTOMER,
    payload,
    cbs,
    cbe,
  }),

  getListSearchCustomer: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_SEARCH_CUSTOMER,
    payload,
    cbs,
    cbe,
  }),

  createCustomer: (payload, cbs, cbe) => ({
    type: actions.CREATE_CUSTOMER,
    payload,
    cbs,
    cbe,
  }),

  getListCountries: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_COUNTRIES,
    payload,
    cbs,
    cbe,
  }),

  getProfileCustomer: (payload, cbs, cbe) => ({
    type: actions.GET_PROFILE_CUSTOMER,
    payload,
    cbs,
    cbe,
  }),

  getCustomer: (payload, cbs, cbe) => ({
    type: actions.GET_CUSTOMER,
    payload,
    cbs,
    cbe,
  }),

  updateCustomer: (payload, cbs, cbe) => ({
    type: actions.UPDATE_PROFILE_CUSTOMER,
    payload,
    cbs,
    cbe,
  }),

  getListStatus: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_STATUS,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
