const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_ADMIN_WALLET_LIST: 'GET_ADMIN_WALLET_LIST',
  GET_ADMIN_WALLET_LIST_SUCCESS: 'GET_ADMIN_WALLET_LIST_SUCCESS',
  CREATE_WALLET_CHAIN: 'CREATE_WALLET_CHAIN',
  UPDATE_WALLET_CHAIN: 'UPDATE_WALLET_CHAIN',
  DELETE_WALLET_CHAIN: 'DELETE_WALLET_CHAIN',

  GET_ADMIN_WALLET_BALANCE: 'GET_ADMIN_WALLET_BALANCE',
  GET_ADMIN_WALLET_BALANCE_SUCCESS: 'GET_ADMIN_WALLET_BALANCE_SUCCESS',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getAdminWalletList: (payload, cbs, cbe) => ({
    type: actions.GET_ADMIN_WALLET_LIST,
    payload,
    cbs,
    cbe,
  }),

  createWalletChain: (payload, cbs, cbe) => ({
    type: actions.CREATE_WALLET_CHAIN,
    payload,
    cbs,
    cbe,
  }),

  updateWalletChain: (payload, cbs, cbe) => ({
    type: actions.UPDATE_WALLET_CHAIN,
    payload,
    cbs,
    cbe,
  }),

  deleteWalletChain: (payload, cbs, cbe) => ({
    type: actions.DELETE_WALLET_CHAIN,
    payload,
    cbs,
    cbe,
  }),

  fetchAdminWalletBalance: (payload) => ({
    type: actions.GET_ADMIN_WALLET_BALANCE,
    payload,
  }),
}
export default actions
