import { combineReducers } from 'redux'
import App from '@iso/redux/app/reducer'
import Auth from '@iso/redux/auth/reducer'
import User from '@iso/redux/user/reducer'
import Customer from '@iso/redux/customer/reducer'
import Transaction from '@iso/redux/transaction/reducer'
import Wallet from '@iso/redux/wallet/reducer'
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer'
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer'
import profile from '@iso/redux/profile/reducer'
import AdminWallet from '@iso/redux/adminWallet/reducer'
import BlockChain from '@iso/redux/blockChain/reducer'
import Currency from '@iso/redux/currency/reducer'
import Staking from '@iso/redux/staking/reducer'
import GameManager from '@iso/redux/game/reducer'

export default combineReducers({
  Auth,
  App,
  User,
  Customer,
  Transaction,
  Wallet,
  ThemeSwitcher,
  LanguageSwitcher,
  profile,
  AdminWallet,
  BlockChain,
  Currency,
  Staking,
  GameManager,
})
