import actions from './actions'

const initState = { listCurrency: null, listCurrencyAddress: undefined }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LIST_CURRENCY_SUCCESS:
      return {
        ...state,
        listCurrency: action.data,
      }
    case actions.GET_ADDRESS_BY_CURRENCY_LIST_SUCCESS:
      return {
        ...state,
        listCurrencyAddress: action.data,
      }

    default:
      return state
  }
}
