import { MORALIS_APP_API, MORALIS_APP_API_KEY } from 'config/constants'
import { createStorageTTL, getFromStorageTTL, MEDIUM_TTL } from '.'

export const fetchDataAndCheckLocaleData = async (account, currencyAddressList, isLoadBalance) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const cache_key = `z:a:w:${account}` // z for lottery, a for admin, w for wallet
        let data

        const content = getFromStorageTTL(cache_key)
        if (content !== null && !isLoadBalance) {
          data = content
        }

        // Ensure we have valid cached data
        if (data === undefined) {
          let result = {}
          try {
            const promiseList = []
            const promiseListBNB = []
            const entriesChain = Object.entries(currencyAddressList)
            for (let j = 0; j < entriesChain.length; j++) {
              const [keyChain, addressList] = entriesChain[j]

              let qtr = ''
              addressList.forEach((addr) => {
                qtr += `&token_addresses=${addr}`
              })

              promiseList.push(
                fetch(`${MORALIS_APP_API}/${account}/erc20?chain=${keyChain}${qtr}`, {
                  method: 'GET',
                  headers: {
                    'x-api-key': MORALIS_APP_API_KEY,
                  },
                }).then(async (res) => await res.json()),
              )
              promiseListBNB.push(
                fetch(`${MORALIS_APP_API}/${account}/balance?chain=${keyChain}`, {
                  method: 'GET',
                  headers: {
                    'x-api-key': MORALIS_APP_API_KEY,
                  },
                }).then(async (res) => await res.json()),
              )
            }
            const response = await Promise.all(promiseList)
            const responseBNB = await Promise.all(promiseListBNB)
            for (let j = 0; j < entriesChain.length; j++) {
              const [keyChain] = entriesChain[j]
              result[keyChain] = []
              if (response[j]?.length !== undefined) {
                result[keyChain] = response[j]
              }
              result[`balance_${keyChain}`] = responseBNB[j]?.balance
            }
          } catch (err) {
            reject(err)
          }

          if (Object.keys(result).length > 0) {
            data = { code: 200, data: { [account]: result } }
            createStorageTTL(cache_key, data, MEDIUM_TTL)
          } else {
            data = { code: 429, data: undefined, message: 'Call limit time' }
          }
        }
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })()
  })
}
