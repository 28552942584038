export const APP_URL = process.env.REACT_APP_URL
export const ROOT_API = process.env.REACT_APP_ROOT_API
export const G_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY

/**
 * Moralis
 */
export const MORALIS_APP_API = process.env.REACT_APP_MORALIS_API
export const MORALIS_APP_API_KEY = process.env.REACT_APP_MORALIS_API_KEY
export const MORALIS_APP_URL = process.env.REACT_APP_MORALIS_DAPP_URL
export const MORALIS_APP_ID = process.env.REACT_APP_MORALIS_DAPP_ID

/**
 * Cache at locale global
 */
export const CACHE_TOKEN = '_lottery_token'
export const CACHE_USER_INFO = '_lottery_userInfo'

/**
 * STATUS PROCESSING
 */
export const STATUS_TRANSACTION = {
  FAIL: 'FAIL',
  COMPLETED: 'COMPLETED',
}
