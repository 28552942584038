import actions from './actions'

const initState = { listWalletChain: null, listWalletWithBalance: {} }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_ADMIN_WALLET_LIST_SUCCESS:
      return {
        ...state,
        listWalletChain: action.data,
      }
    case actions.GET_ADMIN_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        listWalletWithBalance: {
          ...state.listWalletWithBalance,
          ...action.payload,
        },
      }
    default:
      return state
  }
}
