import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListTransactionFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/transaction/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListTransaction() {
  yield takeEvery(actions.GET_LIST_TRANSACTION, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListTransactionFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_TRANSACTION_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListTransaction', error)
      if (cbe) cbe(error)
    }
  })
}

function* getListTransactionByCustomer() {
  yield takeEvery(actions.GET_TRANSACTION_BY_CUSTOMER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListTransactionFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        return data.data
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListTransaction', error)
      if (cbe) cbe(error)
    }
  })
}

function approveTransactionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/transaction/retryWithdraw`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* approveTransaction() {
  yield takeEvery(actions.APPROVE_TRANSACTION, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(approveTransactionFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) return cbs(data.data)
      } else if (cbe) return cbe(data)
    } catch (error) {
      console.error('approveTransaction', error)
      if (cbe) return cbe(error)
    }
  })
}

function rejectTransactionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/transaction/cancelWithdraw`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* rejectTransaction() {
  yield takeEvery(actions.REJECT_TRANSACTION, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(rejectTransactionFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) return cbs(data.data)
      } else if (cbe) return cbe(data)
    } catch (error) {
      console.error('rejectTransaction', error)
      if (cbe) return cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([
    //
    fork(getListTransaction),
    fork(getListTransactionByCustomer),
    fork(approveTransaction),
    fork(rejectTransaction),
  ])
}
